<template>
  <main class="sets-page page">
    <div class="page__inner">
      <div class="container-padding">
        <div class="sets-page__inner">
          <SetsHeaderComponent title="Наборы со вкусом..." />
          <template v-if="set_groups.length">
            <SetsGridComponent v-for="(item, i) in set_groups" :key="i" :data="item" :reverse="i % 2 !== 0" />
          </template>
          <template v-else>
            <span>В данный момент наборы отсутствуют</span>
          </template>
          <!--        <SliderComponent-->
          <!--          :items="setsItems"-->
          <!--          v-slot="{ item }"-->
          <!--          :slider-options="sliderSetOptions"-->
          <!--          :title="'Популярные наборы'"-->
          <!--          :route="{ name: 'home' }"-->
          <!--          button-title="Ещё 135 товаров"-->
          <!--          class="sets-page__slider"-->
          <!--        >-->
          <!--          <SetBuyCardComponent :data="item" />-->
          <!--        </SliderComponent>-->
          <!--        <div class="sets-page__list">-->
          <!--          <SetBuyCardComponent v-for="(item, i) in setsItems" :key="i" :data="item" />-->
          <!--        </div>-->
          <!--        <div class="categories-section">-->
          <!--          <SetsCategoryTabsComponent :data="sets" />-->
          <!--          <div class="categories-section&#45;&#45;right">-->
          <!--            <SetsCategoryComponent-->
          <!--              v-for="(item, i) in sets"-->
          <!--              :data="item.items"-->
          <!--              :key="i"-->
          <!--              :title="item.title"-->
          <!--              :id="`item_${i}`"-->
          <!--            >-->
          <!--              <SetBuyCardComponent v-for="(item, i) in item.items" :key="i" :data="item" :column="true" />-->
          <!--            </SetsCategoryComponent>-->
          <!--          </div>-->
          <!--        </div>-->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SetsGridComponent from "./components/SetsGridComponent.vue";
import SetBuyCardComponent from "components/SetBuyCardComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
// import SetsCategoryComponent from "./components/SetsCategoryComponent.vue";
// import SetsCategoryTabsComponent from "./components/SetsCategoryTabsComponent.vue";
import SetsHeaderComponent from "./components/SetsHeaderComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import SETS_PAGE_STORE from "@/graphql/pages/store/SetsPage.graphql";
export default {
  name: "SetsPge",
  async asyncData({ apollo, store }) {
    let token = store.state.auth_token;
    await apollo.clients.store
      .query({
        query: SETS_PAGE_STORE,
        variables: {},
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        store.state.sets_page.set_groups = data.parent_set_categories_paginate.data;
      });
  },
  computed: {
    set_groups() {
      return this.sliceArray(this.$store.state.sets_page.set_groups, 4);
    },
  },
  data() {
    return {
      sliderSetOptions: {
        slidesPerView: 3,
        grid: {
          rows: 2,
        },
        spaceBetween: 24,
      },
    };
  },
  methods: {
    sliceArray(array, chunk) {
      let sortArray = [];
      for (let i = 0; i < array.length; i += chunk) {
        sortArray.push(array.slice(i, i + chunk));
      }
      return sortArray;
    },
  },
  components: {
    SectionComponent,
    SetsHeaderComponent,
    // SetsCategoryTabsComponent,
    // SetsCategoryComponent,
    SliderComponent,
    SetBuyCardComponent,
    SetsGridComponent,
  },
  metaInfo: {
    titleTemplate: "%s | Зеленое яблоко",
    meta: [
      {
        vmid: "keywords",
        name: "keywords",
        content: "Зеленое яблоко, товары",
      },
      {
        vmid: "description",
        name: "description",
        content: "Интернет магазин  товаров.",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Зеленое яблоко - интернет магазин  товаров.",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: "Интернет магазин  товаров.",
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "article",
      },
      {
        vmid: "og:image",
        name: "og:image",
        content: "https://zelenoe.comrades.dev/static/images/welcome.png",
      },
    ],
  },
};
</script>

<style lang="stylus">
.sets-page{

  &__slider{
    height 474px

    +below(1024px) {
      display none
    }
  }

  &__list {
    display grid
    grid-template-columns 1fr 1fr
    gap 15px

    +above(1023px) {
      display none
    }
  }

  &__inner {
    display flex
    flex-direction column
    gap 24px
  }
}
</style>

<template>
  <div class="sets-header">
    <h2 class="sets-header__title">{{ title }}</h2>
    <!--    <div class="sets-header__btns">-->
    <!--      <div class="sets-header__switch">-->
    <!--        <div-->
    <!--          @click="(switchAll = false), (switchMy = true)"-->
    <!--          class="sets-header__switch-item"-->
    <!--          :class="{ 'sets-header__switch-item&#45;&#45;active': switchMy }"-->
    <!--        >-->
    <!--          Мои-->
    <!--        </div>-->
    <!--        <div-->
    <!--          @click="(switchAll = true), (switchMy = false)"-->
    <!--          class="sets-header__switch-item"-->
    <!--          :class="{ 'sets-header__switch-item&#45;&#45;active': switchAll }"-->
    <!--        >-->
    <!--          Все-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <button class="btn btn&#45;&#45;main btn&#45;&#45;md">-->
    <!--        Создать набор <RemixIconComponent category="system" name="add-circle-line" />-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "SetsHeaderComponent",
  components: { RemixIconComponent },
  props: {
    title: String,
  },
  data() {
    return {
      switchMy: false,
      switchAll: true,
    };
  },
};
</script>

<style lang="stylus">
.sets-header{
  //margin-top 80px
  display flex
  justify-content space-between
  +below(1024px) {
    flex-direction: column-reverse;
    gap 10px
  }

  &__title {
    color: var(--gray-gray-900, #292929);
    font-size: 2rem
    font-weight: 500;
    line-height: 48px; /* 150% */
    margin-bottom: 0

    +below(628px) {
      font-size: 1.5rem
      line-height 32px
    }
  }

  &__btns{
    display flex
    gap 16px
  }
  &__switch{
    padding 0 2px
    background: var(--white);
    border: 1px solid var(--border-color-2);
    border-radius: 8px;
    display flex
    height: 46px;
    gap 3px
    align-items: center;
    transition var(--transition)
    &-item{
      font-weight: 400;
      font-size: 0.875em;
      line-height: 20px;
      color: var(--gray-800);
      padding 9px 57px
      border-radius: 8px;
      cursor pointer
      height 40px
      transition var(--transition)

      +below(1024px) {
        padding: 9px 39px;
      }

      &:hover{
        border: 1px solid var(--gray-800);
      }
      &--active{
        color: var(--white);
        background: var(--gray-800);
        &:hover{
          border: none
        }
      }
    }
  }
}
</style>
